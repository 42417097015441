<template>    
    <section class="choice-section mt-2" v-if="randomProduct" >        
            <div class="section-heading text-center mb-3">
                <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <h5>JOURNEY IS FUN</h5>
                    <h2>BEST CHOICE</h2>
                    <div class="title-icon-divider"><feather-icon icon="BriefcaseIcon" size="16" /></div>                    
                </div>
                </div>
            </div>
            <div class="choice-slider" :style="`background-image:  url(${imgUrl + randomProduct.coverImage}); background-position: center center;  background-repeat: no-repeat;  background-size: cover;`">
                <div class="choice-slider-item" >
                <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="choice-slider-content text-center">
                            <h3>{{randomProduct.name}}</h3>
                            
                            <div v-html="randomProduct.shortdescription" class="mb-2 " id="description-product"></div>
                            <a class="button-primary" @click="showProduct(randomProduct)">SHOW</a>
                        </div>
                    </div>
                </div>
                </div>
               
            </div>        
    </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return {
            imgUrl: process.env.VUE_APP_BASE_URL_SERVER,
        }
    },
    computed:{
        ...mapState('products',['bestSellers']),    
        randomProduct(){
            if(this.bestSellers.length){
                const totalItems = this.bestSellers.length
                const randomIndex = Math.floor(Math.random() * (totalItems - 1 ))
                return this.bestSellers[randomIndex]
            }
            return null
        }
    },
    methods: {
        showProduct(product){      
            this.$router.push({ name: 'sales-product-details', params: { slug: 'products-'+ product.id } })
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/core.scss";
#description-product {
    margin-block-start: 1.5rem;
    color: white;
    font-size: 1.3rem;
}
</style>