<template>
  <section class="destination-section mt-4" v-if="categories.subCategories.length">    
      <div class="section-heading text-center">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <h5>POPULAR OPTIONS</h5>
                <h2>TOP NOTCH EXPERIENCES</h2>
                <div class="title-icon-divider"><feather-icon icon="BriefcaseIcon" size="16" /></div>                
            </div>
          </div>
      </div>
      <div class="destination-inner destination-four-column">
          <div class="row">
            <div class="col-lg-3 col-sm-6" v-for="(category) in categories.subCategories" :key="category.uuid">
                <div class="desti-item text-center">
                  <figure class="desti-image">
                      <img :src="imgCategory(category)" :alt="category.text" height="250px" @error="imgAlternativo">                      
                  </figure>
                  <div class="desti-content" @click="goToViewMoreBySubCategory(category.value)" style="cursor:pointer;">
                      <h3 >
                        <a>{{category.text}}</a>
                      </h3>
                      <div class="meta-cat">
                        {{category.nameCategory}}
                      </div>
                  </div>
                </div>
            </div>
            
          </div>
          <div class="btn-wrap text-center">
            <!-- <a :to="{ name: 'sales' }" class="button-primary">VIEW MORE</a> -->
            <router-link :to="{ name: 'sales' }" class="button-primary">VIEW MORE</router-link>
          </div>
      </div>
  </section>  
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { market } from '@/modules/shop/mixins/market'
import { toJson } from '@/helpers/helpers'

export default {
  mixins: [market],
  
  data(){
    return {
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER,
      imdDefault: require('@/assets/images/default.jpg')        
    }
  },
  computed:{
    ...mapState('start',['categories']),
    ...mapState('shop', ['cart']),

  },
  methods:{
    ...mapActions('products',['fetchProductsBySubCategory','fetchProductsByCategories']),
    ...mapMutations('shop',['addProductInCart','calculateTotalBreakdown']),
    ...mapMutations('start',['setSelectedCategory']),
    ...mapMutations('products',['setProducts']),

    imgAlternativo( event ){
      event.target.src = this.imdDefault
    },
    async goToViewMoreByCategory(category){    
      category.idSubCategory = null
      category.idCategory = category.value      
      this.setSelectedCategory(category)    
      const products = await this.fetchProductsByCategories( category ) 
      this.setProducts( products )  
      this.$router.push({name: 'sales'})
    },
    async goToViewMoreBySubCategory(idSubCategory){   
      const { withSubCategories, hotels, services } = this.categories
      const myCategories =  [...withSubCategories, ...hotels, ...services ]
      const categoria = toJson(myCategories.find(category => category.subcategory.find(subCat => subCat.value === idSubCategory )) ) //category para la peticion     
      categoria.idSubCategory = idSubCategory
      categoria.idCategory = null
      this.setSelectedCategory( categoria )    
      const products = await this.fetchProductsBySubCategory( categoria ) 
      this.setProducts( products )
      this.$router.push({name: 'sales'})
    },
    imgCategory( category){
      const { coverImage } = category      
      if(coverImage){
        return `${this.imgUrl + coverImage}`
      }
      return this.imdDefault
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/core.scss";
</style>