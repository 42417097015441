<template>
  <section class="package-section bg-light-grey">        
    <div class="section-heading text-center">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <h5>EXPLORE OUR BEST SELLERS</h5>
            <h2>POPULAR</h2>
            <div class="title-icon-divider"><feather-icon icon="BriefcaseIcon" size="16" /></div>
          </div>
        </div>
    </div>
    <div class="package-inner package-inner-list mt-3">
      <div class="row">
          <div class="col-lg-6 " v-for="(product, index) in bestSellers" :key="index">
              <div class="package-wrap package-wrap-list">
                  <figure class="feature-image" >
                      <a  @click="showProduct(product)">
                      <img :src="imgUrl + product.coverImage" :alt="product.name" @error="imgAlternativo" style="height: 200px; width:100%;">
                      </a>
                      <div class="package-price">
                      <h6>
                          Starting at <span> ${{product.detail[0].saleprice}}</span> 
                      </h6>
                      </div>
                      <div class="package-meta text-center">
                      <ul>
                          <li>
                              <i class="far fa-clock"></i>
                              <!--  -->
                              <b  style="font-size:1.1rem;">{{product.categoryName}}</b>
                              
                          </li>
                          
                      </ul>
                      </div>
                  </figure>
                  <div class="package-content">
                      <h5 @click="showProduct(product)" class="title-item"> <b>  {{product.name}}</b></h5>
                   
                      <SingleDetail :singleDetail="product.detail[0]"  :isBestSeller="true" :product="product" v-if="product.detail.length == 1 "/>
                      <ProductDetails :productDetails="product.detail" :isBestSeller="true" :product="product" v-else/>  
                      <div class="btn-wrap">
                          <a  class="button-text width-6"><b v-if="product.total !== '0.00'" style="font-size:.9rem;"> Total: ${{ product.total }}  USD</b></a>
                          <a  class="button-text width-6" @click="handlerAddToCart(product)"><feather-icon icon="ShoppingCartIcon" size="16" class="ml-1"/></a>
                        </div>
                  </div>
              </div>
          </div>                    
      </div>                     
      <div role="tablist" class="list-t"></div>          
    </div>            
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { market } from '@/modules/shop/mixins/market'
import { toJson } from '@/helpers/helpers'

import ProductDetails from '@/modules/shop/components/experiences/ProductDetails'
import SingleDetail from '@/modules/shop/components/experiences/SingleDetail'

export default {
  mixins: [ market ],
  
  components: {
    SingleDetail,
    ProductDetails,
  },
 
  data(){
    return {
      imgUrl: process.env.VUE_APP_BASE_URL_SERVER,
      imdDefault: require('@/assets/images/default.jpg') 
    }
  },
  computed:{
    ...mapState('products',['bestSellers']),
    ...mapState('shop', ['cart']),
  },
  methods:{
    ...mapMutations('shop',['addProductInCart','calculateTotalBreakdown']),
    ...mapMutations('products',['resetQtyProductDetail']),
    
    imgAlternativo( event ){
      event.target.src = this.imdDefault
    },
    async handlerAddToCart(product){
      let item = toJson({...product})//spread
      const productsAddCart = this.generateProductsToAddCart(item) // desde el mixin
      //ciclo asyncrono para ir añadiendo al cart
      productsAddCart.forEach( async productCart => {          
        const { qty } = productCart.detailSelected //tomo su cantidad
        const productFormatted = this.formatProductBeforeAddingToCart(productCart) //desde el mixin, formateo el item
        const productIsRepeated = this.existsInCart( productFormatted )
        if( !productIsRepeated ){ // si producto no se repite         
          this.addProductInCart(productFormatted) // agrego el producto formateado
          await this.setConfigBlackoutDatesProduct(productFormatted, qty)  // desde el mixin market 
        } else {
          await this.addOneMoreQty(productFormatted)
        }
      })  
      
      this.resetQtyProductDetail( {idProduct:product.id, isBestSeller: true} ) // reseteo los qty detail del producto                           
    },
    async addOneMoreQty(product){
      const { modelType, detailId, qty }  = product   
      const prodInCart = this.cart.find( product => product.detailId === detailId && product.modelType === modelType )
      prodInCart.qty = prodInCart.qty + qty//aumento la cantidad
      this.calculateTotalBreakdown()
      await this.qtyHasChanged(prodInCart) // desde el mixim market
    },
     showProduct(product){      
      this.$router.push({ name: 'sales-product-details', params: { slug: 'products-'+ product.id } })      
    },
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/core.scss";
.details-product{    
    height: 165px;
    overflow: auto;  
    padding-inline-end: .5rem;      
  }  
  .details-product::-webkit-scrollbar-track {
    box-shadow:  inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  
  .details-product::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
  }
  
  .details-product::-webkit-scrollbar-thumb{
    background-color: #ff9f43;	
    background-image: -webkit-linear-gradient(45deg,rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent)
  }
  .badge-price{
    font-size: .7rem;
    font-weight: bold;
    background-color: #F56960;
    color: #ffffff;
    padding: .3rem .6rem;
    margin-block-end: 1rem;
    text-align: center;
  }

  .title-item{
    cursor: pointer;
  }

  .title-item:hover{
    color: #0085BA;
  }

</style>
  